import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  obtenerSucursales,
  tramiteAnular,
  tramiteListar,
  tramiteMostrar,
  tramiteValidar,
} from "../../api/tramiteApi";
import { useNavigate } from "react-router-dom";
import {
  codigo_enviar_iofe,
  codigo_tramite_agregar,
  codigo_tramite_anular,
  ruta_sin_acceso,
  ruta_tramite_agregar,
  ruta_tramite_editar,
} from "../../constants/constants-rutas-codigo";
import { DataContext } from "../../context/DataContext";
import {
  allowOnlyNumbers,
  descargarArchivoDesdeBase64,
  guardarLocalStorage,
  obtenerLocalStorage,
  obtenerNombreYArchivoBase64,
} from "../../resources/funciones";
import { eliminarArchivoApi, getArchivo, getArchivos, subirArchivo } from "../../api/gestorApi";
import { verificarUsuario } from "../../api/loginApi";
import { createPruebaVida, tramiteIofe, tramitePruebaVida, updatePruebaVida } from "../../api/iofeApi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2'
import { VisualizadorArchivos } from "../gestor-de-archivos/VisualizadorArchivos";
const colors = ['#90c2ff', '#ebd2a4', '#90c2ff', '#7bbfc3', '#c3c3c3', '#ece9d2', '#d9effc', '#c8bcb1', '#ecad8f', '#c1cd97']
export const Bandeja = () => {
  const navigate = useNavigate();
  const { listaEstadoContrato } = useContext(DataContext);
  let fecha = new Date();
  fecha.setDate(fecha.getDate());
  let fechaActual = fecha.toISOString().substring(0, 10);
  fecha.setDate(fecha.getDate() - 20);
  let fechaAyer = fecha.toISOString().substring(0, 10);
  const token = obtenerLocalStorage("usuario")?.token;
  const estilosCompania = obtenerLocalStorage("usuario")?.estilo;
  const [listaSedes, setListaSedes] = useState([]);
  const [listaSucursales, setListaSucursales] = useState([]);
  const [listaTramites, setListaTramites] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [trazabilidad, setTrazabilidad] = useState([])
  const [enviarTramiteIofe, setEnviarTramiteIofe] = useState();
  const [detalleId, setDetalleId] = useState("");
  const [datosPV, setDatosPV] = useState([]);
  const [estadoFirma, setEstadoFirma] = useState({});
  const [detalleT, setDetalleT] = useState({})
  const [showDetallesInmatriculacion, setShowDetallesInmatriculacion] = useState(false)
  const [percent, setPercent] = useState(0);
  const [paso, setPaso] = useState('');
  const [siguientePaso, setSiguientePaso] = useState('');
  const [showModalArchivosTramite, setShowModalArchivosTramite] = useState(false);
  const [tramiteId, setTramiteId] = useState(null);
  const [nombreArchivoVisualizar, setNombreArchivoVisualizar] = useState("");
  const [listaArchivosVisualizar, setListaArchivosVisualizar] = useState([]);
  const [numeroArchivoInicial, setNumeroArchivoInicial] = useState(1);
  const [open, setOpen] = useState(false);
  const [archivosByTramiteId, setArchivosByTramiteId] = useState([]);
  const [showSubirArchivo, setShowSubirArchivo] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const [tipoSeleccionado, setTipoSeleccionado] = useState("");
  const [archivoIdVisualizar, setArchivoIdVisualizar] = useState("");


  const handleCloseSubirArchivo = (event) => {
    if (event) {
      event.preventDefault(); 
    }
    setShowSubirArchivo(false); 
    resetArchivo()
  };


  const handleCloseDetallesInmatriculacion = () => setShowDetallesInmatriculacion(false)
  const handleCloseArchivosTramite = () => setShowModalArchivosTramite(false)
  const handleCloseVisualizadorArchivo = () => setOpen(false);
  const [tipoCombustion, setTipoCombustion] = useState();


  const { listaCombustible } = useContext(DataContext);

  const copiarUrl = (url) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 1200,
      timerProgressBar: true,
    })
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(url)
      Toast.fire({
        icon: 'success',
        title: 'Enlace Copiado',
      })
    } else {
      const input = document.createElement('input')
      input.style.position = 'absolute'
      input.style.opacity = '0'
      input.value = url
      document.body.appendChild(input);
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input);
      Toast.fire({
        icon: 'success',
        title: 'El enlace se ha copiado',
      })
    }
  }
  const { register: registerFilter, handleSubmit: handleFilter, watch, setValue, formState: { errors } } = useForm({
    defaultValues: {
      Tramite: "",
    },
  });

  const { register: registerArchivo, handleSubmit: handleArchivo, watch: watchArchivo, reset: resetArchivo } = useForm({});

  const onSubmitBuscar = async (data) => {
    const dataPOST = {
      RegFechIni: data?.Desde,
      RegFechFin: data?.Hasta,
      SubCia: data?.Sucursal,
      SedeTramit: data?.Sede,
      EstTramit: data?.Estado,
      IdTramit: data?.Tramite,
      NomComTramit: data?.Cliente,
      tokenCompañia: token,
    }
    const { datos_tramite } = await tramiteListar(dataPOST);

    setListaTramites(datos_tramite);
  };
  const irTramite = async () => {
    const dataPOST = {
      token: token,
      codigo: codigo_tramite_agregar,
    };
    const { autenticado } = await verificarUsuario(dataPOST);
    if (autenticado) {
      navigate(ruta_tramite_agregar);
    } else {
      navigate(ruta_sin_acceso);
    }
  };
  const editarTramite = async (idTramite, estadoTramite) => {
    const dataPOST = {
      IdTramit: idTramite,
      tokenCompañia: token,
    };
    const {/*  status,  */datos_tramite } = await tramiteMostrar(dataPOST);

    try {
      const datos_compradores_formateado = datos_tramite[0].Personas.map((c) => ({
        TipoDoc: c.PerTipDoc,
        NumDoc: c.PerNumDoc,
        ApellidoP: c.PerApllPat,
        ApellidoM: c.PerApllMat,
        Nombre: c.PerNomb,
        RazonSocial: c.PerRazSoc,
        Ubigeo: c.PersonaDireccion.PerDirUbig.TUbiCod_R,
        CodUbig: c.PersonaDireccion.PerDirUbig.TUbiCod_R,
        Domicilio: c.PersonaDireccion.PerDirDomi,
        Telefono: c.PerTelefono,
        Email: c.PerEmail,
        EstCivil: c.PerEstCivil,
        UnionHecho: c.PerUnionHech,
        SepPatr: c.PerSepaPatri,
        SocCony: c.PerSocConyug,
        PartReg: c.PerPartReg,
        OficReg: c.PerOficReg,
        ConyTipoDoc: c.PerConyuge?.PerTipDoc || "",
        ConyNumDoc: c.PerConyuge?.PerNumDoc || "",
        ConyApellidoP: c.PerConyuge?.PerApllPat || "",
        ConyApellidoM: c.PerConyuge?.PerApllMat || "",
        ConyNombre: c.PerConyuge?.PerNomb || "",
        ConyTelefono: c.PerConyuge?.PerTelefono || "",
        ConyEmail: c.PerConyuge?.PerEmail || "",
        ConyUbigeo: c.PerConyuge?.PersonaDireccion?.PerDirUbig.TUbiCod_R || "",
        ConyDomicilio: c.PerConyuge?.PersonaDireccion?.PerDirDomi || "",
        ConyEstCivil: c.PerConyuge?.PerEstCivil || "",
        RepTipoDoc: c.PerRepresentantes?.[0]?.PerTipDoc || "",
        RepNumDoc: c.PerRepresentantes?.[0]?.PerNumDoc || "",
        RepApellidoP: c.PerRepresentantes?.[0]?.PerApllPat || "",
        RepApellidoM: c.PerRepresentantes?.[0]?.PerApllMat || "",
        RepNombre: c.PerRepresentantes?.[0]?.PerNomb || "",
        RepPartReg: c.PerRepresentantes?.[0]?.PerPartReg || "",
        RepOficReg: c.PerRepresentantes?.[0]?.PerOficReg || "",
        RepEmail: c.PerRepresentantes?.[0]?.PerEmail || "",
        RepTelefono: c.PerRepresentantes?.[0]?.PerTelefono || "",
      }));
      guardarLocalStorage(
        {
          datos_tramite,
          datos_compradores: datos_compradores_formateado,
          idTramite,
        },
        "tramite"
      );
      navigate(ruta_tramite_editar, {
        state: { datos_tramite, datos_compradores: datos_compradores_formateado, estadoTramite },
      });
    } catch (error) {
      console.error("Message: ", error);
    }
  };
  const pruebaVidaUpdate = async (CompradorId) => {
    const result = await Swal.fire({
      title: '¿Deseas actualizar la prueba de vida?',
      text: `Inmatriculacion ${detalleId}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, actualizar',
      cancelButtonText: 'Cancelar',
    })
    if (result.isConfirmed) {
      const headers = {
        'token': token,
        'Content-Type': 'application/json'
      };
      const dataPOST = {
        tramite: detalleId,
        personaID: CompradorId
      };
      const { data } = await updatePruebaVida(dataPOST, headers);
      if (data) {
        Swal.fire({
          icon: 'success',
          title: "Se actualizo correctamente la prueba de vida",
          html: `    `
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: "No se pudo actualizar la prueba de vida",
          text: 'Hubo un problema con la actualizacion, intentelo de nuevo mas tarde.'
        });
      }
    }
  }
  const pruebaVidaCreate = async () => {
    const result = await Swal.fire({
      title: '¿Deseas volver a generar la prueba de vida?',
      text: `Inmatriculacion ${detalleId}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, generar',
      cancelButtonText: 'Cancelar',
    })
    if (result.isConfirmed) {
      const headers = {
        'token': token,
        'Content-Type': 'application/json'
      };
      const dataPOST = {
        tramite: detalleId
      };
      const { data } = await createPruebaVida(dataPOST, headers);
      if (data) {
        Swal.fire({
          icon: 'success',
          title: "Se creo correctamente la prueba de vida",
          html: `    `
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: "No se pudo crear la prueba de vida",
          text: 'Hubo un problema con la actualizacion, intentelo de nuevo mas tarde.'
        });
      }
    }
  }
  const anularTramite = async (idTramite) => {
    const dataPOSTPermiso = {
      token: token,
      codigo: codigo_tramite_anular,
    };
    const { autenticado } = await verificarUsuario(dataPOSTPermiso);
    if (autenticado) {
      const dataPOST = {
        IdTramit: idTramite,
        EstTramite: "00",
        tokenCompañia: token,
      };
      await tramiteAnular(dataPOST);
      const dataPOST2 = {
        RegFechIni: watch("Desde"),
        RegFechFin: watch("Hasta"),
        SubCia: watch("Sucursal"),
        SedeTramit: watch("Sede"),
        EstTramit: watch("Estado"),
        IdTramit: watch("Tramite"),
        NomComTramit: watch("Cliente"),
        tokenCompañia: token,
      };
      const { datos_tramite } = await tramiteListar(dataPOST2);
      setListaTramites(datos_tramite);
    } else {
      setMensajeAlerta("No tiene autorización para anular la inmatriculación");
      setTimeout(() => {
        setMensajeAlerta("");
      }, 3000);
    }
  };
  const validarTramite = async (idTramite, estTramite) => {
    if (estTramite === "03") {
      setIsLoading(true)
      const dataPost = {
        TramitId: idTramite,
        tokenCompañia: token
      }
      const { status, message } = await tramiteValidar(dataPost)
      setMensajeAlerta(message);
      setIsLoading(false);
      if (status === 1) {
        onSubmitBuscar(watch());
        Swal.fire({
          title: "Tramite Validado",
          text: message,
          icon: "success"
        });
      }
      else {
        Swal.fire({
          title: message,
          icon: "error",
        });
      }
      setTimeout(() => {
        setMensajeAlerta("");
      }, 2000)
    } else {
      setMensajeAlerta("El tramite debe estar Procesado");
      setTimeout(() => {
        setMensajeAlerta("");
      }, 2000);
    }
  }
  const enviarIofe = async (idTramite, estTramite) => {
    if (estTramite === "05") {
      const dataPOST = {
        token: token,
        codigo: codigo_enviar_iofe,
      };
      const { autenticado } = await verificarUsuario(dataPOST);
      if (autenticado) {
        setIsLoading(true);
        const dataPOST2 = {
          TramitId: idTramite,
          tokenCompañia: token,
        };
        const { status, message } = await tramiteIofe(dataPOST2);
        setMensajeAlerta(message);
        setIsLoading(false);
        if (status === 1) {
          onSubmitBuscar(watch());
        }
        setTimeout(() => {
          setMensajeAlerta("");
        }, 2000);
      } else {
        setMensajeAlerta("No tiene permisos para realizar esta accion");
        setTimeout(() => {
          setMensajeAlerta("");
        }, 2000);
      }
    } else {
      setMensajeAlerta("El tramite debe estar Procesado");
      setTimeout(() => {
        setMensajeAlerta("");
      }, 2000);
    }
  };
  const handleVerDetalles = async (idTramite) => {
    setIsLoading(true)
    const dataPOST = {
      TramitId: idTramite,
    };
    const headers = {
      'token': token,
      'Content-Type': 'application/json'
    };
    setDatosPV([])
    setEstadoFirma({})
    setDetalleT({})
    setTrazabilidad([])
    setShowDetallesInmatriculacion(true)
    setDetalleId(idTramite)
    const { status, message, data } = await tramitePruebaVida(dataPOST, headers);
    setMensajeAlerta(message);
    setIsLoading(false);
    setPercent(data.progreso.porcentaje);
    setPaso(data.progreso.estado);
    setSiguientePaso(data.progreso.siguiente)
    if (status === 1) {
      setDatosPV(data.pruebaVida)
      setEstadoFirma(data.estadoFirma)
      setDetalleT(data.detalle);
      setTrazabilidad(data.trazabilidad)
      setDetalleId(idTramite)
    }
    setTimeout(() => {
      setMensajeAlerta("");
    }, 2000);
  };
  const verArchivos = async (idTramite) => {
    setTramiteId(idTramite);
    const dataPOST = {
      TramitId: idTramite,
    };
    const { archivos } = await getArchivos(dataPOST);
    const archivosOrdenados = archivos.sort((a, b) => b.Orden - a.Orden)
    setArchivosByTramiteId(archivosOrdenados)
    setShowModalArchivosTramite(true)
  };
  const buscarTramites = async () => {
    setListaTramites(null);
    const dataPOST = {
      RegFechIni: watch("Desde"),
      RegFechFin: watch("Hasta"),
      SubCia: watch("Sucursal"),
      SedeTramit: watch("Sede"),
      EstTramit: watch("Estado"),
      IdTramit: watch("Tramite"),
      NomComTramit: watch("Cliente"),
      tokenCompañia: token,
    }
    const { datos_tramite } = await tramiteListar(dataPOST);
    setListaTramites(datos_tramite);
  };
  const obtenerCompaniasYSucursales = async () => {
    const dataPOST = {
      token: token,
    };
    const { sucursales } = await obtenerSucursales(dataPOST);
    setListaSucursales(sucursales);
    setValue("Sucursal", sucursales[0].id);
    return {
      sucursales,
    };
  };
  const obtenerSedesXSucursal = (idSucursal, sucursales) => {
    let sedes = []
    setValue("Sucursal", idSucursal)
    if (idSucursal === "") {
      sucursales.forEach(item => {
        sedes = sedes.concat(item.sedes)
      });
    } else {
      const sucursal = sucursales.find((item) => item.id === idSucursal);
      sedes = sucursal.sedes;
    }
    setValue("Sede", sedes.length > 1 ? "" : sedes[0]?.id)
    setListaSedes(sedes);
  }
  useEffect(() => {
    const cargarDatosIniciales = async () => {
      const { sucursales } = await obtenerCompaniasYSucursales();
      obtenerSedesXSucursal("", sucursales);
      await buscarTramites();
      const dataPOST = {
        token: token,
        codigo: codigo_enviar_iofe,
      }
      const { autenticado } = await verificarUsuario(dataPOST);
      setEnviarTramiteIofe(autenticado);
    }
    cargarDatosIniciales()
    const interval = setInterval(async () => {
      await buscarTramites()
    }, 300000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, []);
  const getColorClass = (percent) => {
    return 'bg-primary';
  }
  const descargarArchivo = async (idTramite, nombreArchivo, Archivo_Id) => {
    const dataPOST = {
      TramitId: idTramite,
      archivo: Archivo_Id,
    };
    const { archivo } = await getArchivo(dataPOST);
    descargarArchivoDesdeBase64(archivo, nombreArchivo);
  };
  const eliminarArchivo = async (Archivo_Id) => {
    const dataPOST = {
      tramite: tramiteId,
      archivo: Archivo_Id,
    };
    let resultadoArchivos = await eliminarArchivoApi(dataPOST)
    const archivosOrdenados = resultadoArchivos.archivo.sort((a, b) => b.Orden - a.Orden)
    setArchivosByTramiteId(archivosOrdenados)
    Swal.fire({
      icon: 'success',
      title: "Archivo eliminado correctamente",
    });
  };

  const onSubmit = async (data) => {
    if (
      data.archivo[0].type === "application/pdf" ||
      data.archivo[0].type === "text/xml"
    ) {

      const respData = guardarArchivo(data);
      if (respData) {
        Swal.fire("¡Archivo subido correctamente!", "", "success");
        handleCloseSubirArchivo();
      } else {
        Swal.fire("Hubo un error al subir el archivo", "", "error");
      }
    } else {
      Swal.fire("Tipo de archivo no válido", "", "error");
    }
  };

  const guardarArchivo = async (data) => {

    try {
      const nombreArchivo = data.archivo[0].name;
      const { archivoBase64 } = await obtenerNombreYArchivoBase64(data.archivo[0]);
      let fileBase64
      if (archivoBase64.startsWith("data:application/pdf")) {
        fileBase64 = archivoBase64.slice(28);
      } else if (archivoBase64.startsWith("data:text/xml")) {

        fileBase64 = archivoBase64.slice(21);
      } else {
        throw new Error("Tipo de archivo no soportado");
      }
      const nuevoArchivo = {
        idTramite: tramiteId,
        Nom_archv: nombreArchivo,
        pdfBase64: fileBase64,
        tipo: data.tipoArchivo,
        caracteristicas: {
          tipoCombustion: data.tipoCombustion || "",
          combustible: data.combustible || "",
          peso_neto: data.pesoN / 1000 || "",
          carga_util: data.cargaU / 1000 || ""
        }
      };

      const { status } = await subirArchivo(nuevoArchivo);
      if (status === 1) {
        const dataPOST = {
          TramitId: tramiteId,
        }
        const listaNuevoArchivo = await getArchivos(dataPOST);
        setArchivosByTramiteId(listaNuevoArchivo.archivos.sort((a, b) => b.Orden - a.Orden))
        resetArchivo()
        return true
      } else {
        return false
      }
    } catch (error) {

    }
  }

  const handleOpen = () => setOpen(true);
  const verArchivo = (nombreArchivo, archivoId, listaArchivos, numeroArchivo) => {
    setNombreArchivoVisualizar(nombreArchivo);
    setArchivoIdVisualizar(archivoId);
    setListaArchivosVisualizar(listaArchivos);
    setNumeroArchivoInicial(numeroArchivo);
    handleOpen();
  };
  return (
    <>
      <div className="p-1">
        <div className="container pt-2">
          <h4 className="text-center mt-3 ">Bandeja de Inmatriculacion</h4>
          <div className="row mt-3">
            <div className="col-md-3">
              <label className="form-label bold" htmlFor="form-sucursal">Sucursal</label>
              <select
                className="form-select form-select-sm"
                id="form-sucursal"
                {...registerFilter("Sucursal")}
                value={watch("Sucursal")}
                onChange={(e) =>
                  obtenerSedesXSucursal(e.target.value, listaSucursales)
                }
              >
                {listaSucursales.length > 1 && <option value={""}>TODOS</option>}
                {listaSucursales?.length > 0 &&
                  listaSucursales.map((e, index) => (
                    <option key={index} value={e?.id}>
                      {e?.tipo}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label bold">Sede</label>
              <select
                className="form-select form-select-sm"
                {...registerFilter("Sede")}
                value={watch("Sede")}
              >
                {listaSedes.length > 1 && <option value={""}>TODOS</option>}
                {listaSedes?.length > 0 &&
                  listaSedes.map((e, index) => (
                    <option key={index} value={e?.id}>
                      {e?.tipo}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3">
              <label className="form-label bold">Desde</label>
              <input
                className="form-control form-control-sm"
                type="date"
                defaultValue={fechaAyer}
                {...registerFilter("Desde")}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label bold">Hasta</label>
              <input
                className="form-control form-control-sm"
                type="date"
                defaultValue={fechaActual}
                {...registerFilter("Hasta")}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label bold">Cliente</label>
              <input
                type="text"
                className="form-control form-control-sm px-1"
                {...registerFilter("Cliente")}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label bold">Tramite</label>
              <input
                type="text"
                className="form-control form-control-sm px-1"
                {...registerFilter("Tramite")}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label bold">Estado</label>
              <select
                className="form-select form-select-sm"
                defaultValue="T"
                {...registerFilter("Estado")}
              >
                <option value="T">Todos</option>
                {listaEstadoContrato.map((estCont, key) => {
                  if (estCont.EstContEst === "S") {
                    return (
                      <option key={key} value={estCont.EstContCod}>
                        {" "}
                        {estCont.EstContDes}{" "}
                      </option>
                    )
                  } else {
                    return null
                  }
                })}
              </select>
            </div>
            <div className="col-md-3 d-flex justify-content-center align-items-end">
              <button
                className="col-md-12 btn btn-sm btn-main"
                onClick={handleFilter(onSubmitBuscar)}
              >
                Buscar
              </button>
            </div>
          </div>
          <div className="mt-3 ps-1 d-flex">
            <div>
              <OverlayTrigger overlay={<Tooltip>Agregar Tramite</Tooltip>}>
                <button className="btn btn-outline-main" onClick={irTramite}><strong>+</strong></button>
              </OverlayTrigger>
            </div>
            {isLoading ? (
              <div className="text-center" style={{ flex: "1" }}>
                <strong>Cargando... </strong>
                <div
                  className="spinner-border text-secondary spinner-border-sm align-middle"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="text-center" style={{ flex: "1" }}>
                <strong>{mensajeAlerta}</strong>
              </div>
            )}
          </div>
        </div>
        <div className="container-fluid ps-2 pe-2 mt-3 ">
          <div className="bold-1">
            {listaTramites &&
              <span>
                Total {listaTramites.length}
              </span>
            }
          </div>
          <table className="table border table-responsive">
            <thead>
              <tr className="bg-white">
                <th scope="col">Tramite</th>
                <th scope="col" style={{ minWidth: "7rem" }}>Creado</th>
                <th scope="col">Cliente</th>
                <th scope="col">Chasis</th>
                <th scope="col" style={{ width: "9rem" }}>Titulo</th>
                <th scope="col">Sede</th>
                <th scope="col">Estado</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {listaTramites ? (
                <>
                  {listaTramites.length > 0 ? (
                    listaTramites.map((t, index) => (
                      <>
                        <tr className={index % 2 === 0 ? "bg-light" : "bg-white"}>
                          <td className="border-bottom-0 bold-1">{t.TramitId}</td>
                          <td className="border-bottom-0">{t.TramitFchReg}</td>
                          <td className="border-bottom-0">{t.TramitNomClientes.toUpperCase()}</td>
                          <td className="border-bottom-0">{t.chasis}</td>
                          <td className="border-bottom-0"> {t?.TramitSunAnio} {t?.TramitSunTit}</td>
                          <td className="border-bottom-0">{t.sede}</td>
                          <td className="border-bottom-0">{t.estado}</td>
                          <td className="border-bottom-0 text-center">
                            <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-pencil"
                                viewBox="0 0 16 16"
                                style={{
                                  color: estilosCompania?.boton_primario,
                                  cursor: "pointer",
                                }}
                                onClick={() => editarTramite(t?.TramitId, t?.TramitEst)}
                              >
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                              </svg>
                            </OverlayTrigger>
                          </td>
                          <td className="border-bottom-0 text-center">
                            <OverlayTrigger overlay={<Tooltip>Anular</Tooltip>}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-x-circle"
                                viewBox="0 0 16 16"
                                style={{
                                  color:
                                    t.TramitEst !== "06"
                                      ? estilosCompania?.boton_secundario
                                      : "grey",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  anularTramite(t?.TramitId, t?.TramitEst)
                                }
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </OverlayTrigger>
                          </td>
                          <td className="border-bottom-0 text-center">
                            <OverlayTrigger
                              overlay={<Tooltip>Ver documentos</Tooltip>}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16"
                                style={{ color: estilosCompania?.boton_primario, cursor: "pointer" }}
                                onClick={() => verArchivos(t?.TramitId)}
                              >
                                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                              </svg>
                            </OverlayTrigger>
                          </td>
                          <td className="border-bottom-0 text-center" >
                            <OverlayTrigger overlay={<Tooltip>Ver Detalles</Tooltip>}>
                              <svg
                                style={{ color: estilosCompania?.boton_primario, cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="currentColor"
                                className="bi bi-info-circle-fill"
                                viewBox="0 0 16 16"
                                onClick={() =>
                                  handleVerDetalles(t?.TramitId)
                                }
                              >
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                              </svg>
                            </OverlayTrigger>
                          </td>
                          <td className="border-bottom-0 text-center ps-1">
                            {
                              t.TramitEst === '03' ?
                                <div className="parpadeo">
                                  <OverlayTrigger
                                    overlay={<Tooltip>Validar Tramite</Tooltip>}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="19"
                                      height="19"
                                      fill="white"
                                      className="bi bi-clipboard-check"
                                      viewBox="0 0 16 16"
                                      style={{
                                        color: "#0074f0",
                                        cursor: "pointer"
                                      }}
                                      onClick={() =>
                                        validarTramite(t.TramitId, t.TramitEst)
                                      }
                                    >
                                      <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1" />
                                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                  </OverlayTrigger>
                                </div>
                                : null}
                            {enviarTramiteIofe && t.TramitEst === "05" ? (
                              <OverlayTrigger
                                overlay={<Tooltip>Enviar IOFE</Tooltip>}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-lock"
                                  viewBox="0 0 16 16"
                                  style={{
                                    color: t?.TramitEst !== "05" ? "grey" : "green",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    enviarIofe(t?.TramitId, t?.TramitEst)
                                  }
                                >
                                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                                </svg>
                              </OverlayTrigger>
                            ) : null}
                          </td>
                        </tr>
                        <tr >
                          <td colSpan={12} className={index % 2 === 0 ? "bg-light" : "bg-white"}>
                            {t.TramitEst === '03' ?
                              <p className="bold d-flex" style={{ color: "#143465" }} >Siguiente paso: {t.next} &nbsp;
                                <span className="parpadeo">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    fill="white"
                                    className="bi bi-clipboard-check "
                                    viewBox="0 0 16 16"
                                    style={{
                                      color: "#0074f0",
                                    }}
                                  >
                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                  </svg>
                                </span>
                              </p>
                              :
                              <p className="bold" style={{ color: "#143465" }}>Siguiente paso:  {t.next}
                              </p>
                            }
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={12}>
                        No hay tramites
                      </td>
                    </tr>
                  )}
                </>
              )
                :
                (
                  <tr>
                    <td className="text-center" colSpan={12}>
                      <div className="d-grid text-center ">
                        <div className="container">
                          <div className="spinner-border text-primary text-center" role="status" />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showDetallesInmatriculacion} onHide={handleCloseDetallesInmatriculacion} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Inmatriculacion {detalleId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center h5 bold-1">Estado actual: {paso} </p>
          <div className="progress" style={{ height: "20px" }}>
            <div className={`progress-bar ${getColorClass(percent)}`} role="progressbar" style={{ width: `${percent}%` }} aria-valuemin="0" aria-valuemax="100">{percent}%</div>
          </div>
          <p>
            <span className="bold-1">Siguiente paso: {siguientePaso}</span>
            {detalleT.estado === "11" &&
              <span className="float-end pt-1" > <a href="https://www.placas.pe/Public/CheckPlateStatus.aspx" target="_blank" rel="noopener noreferrer">
                <button type="button" title="Consulta el estado actual de la placa en la AAP" className="btn btn-outline-info dw-bold" >Consulta placa</button>
              </a> </span>
            }
          </p>
          <br />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-9">
                <div className="alert alert-secondary ">
                  {detalleT?.asesor ?
                    <div>
                      <div className="container mb-2">
                        <div className="row">
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1" >
                            <span className="fs-8"><b>F. Creacion</b></span>
                            <span className="fs-8">{detalleT.registrado}</span>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1"  >
                            <span className="fs-8"><b>F. Inicio Firmas</b></span>
                            <span className="fs-8">{detalleT.enviado} &nbsp;</span>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1">
                            <span className="fs-8"><b>F. Firmas Completas </b></span>
                            <span className="fs-8">{detalleT.firmaCliente}&nbsp;</span>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1">
                            <span className="fs-8"><b>F. Tramite Placa</b></span>
                            <span className="fs-8"> &nbsp;</span>
                          </div>
                        </div>
                      </div>
                      <div className="container mt-2">
                        <div className="row">
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1" >
                            <span className="fs-8"><b>Asesor</b></span>
                            <span className="fs-8">{detalleT.asesor}</span>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1" >
                            <span className="fs-8"><b>Representante</b></span>
                            <span className="fs-8">{detalleT.representante}</span>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1">
                            <span className="fs-8"><b>Placa</b></span>
                            <span className="fs-8">{detalleT.placa}</span>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6 col-6 text-center p-0 m-0 d-grid pb-1">
                            <span className="fs-8"><b>Código TIVE</b></span>
                            <span className="fs-8">{detalleT.codigo_sunarp}</span>
                          </div>
                        </div>
                      </div>
                    </div> :
                    <div className="d-grid text-center ">
                      <div className="container">
                        <div className="spinner-border text-secondary text-center" role="status" />
                      </div>
                      <span>Obteniendo datos...</span>
                    </div>
                  }
                </div>
                <div className="alert alert-success mb-2">
                  {estadoFirma.estado ? datosPV.length > 0 ?
                    <div className="container">
                      {datosPV.map((item, index) => (
                        <div key={`pv_${index}`}>
                          <div className="row">
                            <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12 text-center fs-7">
                              <span ><b>Prueba de Vida</b></span><br />
                              <span >{item.cliente}</span>
                            </div>
                            <div className="col-5 col-xl-4 col-lg-4 col-md-6 col-sm-5 text-center fs-7">
                              <span ><b>Estado</b></span><br />
                              <span >{item.estado}</span>
                            </div>
                            {item.estado !== "Aceptado" &&
                              <div className="col-7 col-xl-4 col-lg-4 col-md-6 col-sm-7 text-center">
                                <h4 className="fs-7"><b>Enlace</b></h4>
                                <span className="fs-7">
                                  {/*
                                <a className={`btn btn-success${!item.url ? "disabled" : ""}`} href={item.url} role="button" target="_blank" rel="noopener noreferrer">Iniciar Prueba</a>
                                */
                                  }
                                  <OverlayTrigger overlay={<Tooltip>Copiar Enlace</Tooltip>} >
                                    <button className="btn btn-main ms-1" onClick={() => copiarUrl(item.url)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-copy"
                                        viewBox="0 0 16 16">
                                        <path
                                          fillRule="evenodd"
                                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0
0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                        />
                                      </svg>
                                    </button>
                                  </OverlayTrigger>
                                  {detalleT.estadopv == 1 && item.estado !== "Aceptado" &&
                                    <OverlayTrigger overlay={<Tooltip>Actualizar Prueba Vida</Tooltip>}>
                                      <button className="btn btn-main ms-1" onClick={() => pruebaVidaUpdate(item.clienteId)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-repeat" viewBox="0 0 16 16">
                                          <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z" />
                                        </svg>
                                      </button>
                                    </OverlayTrigger>
                                  }
                                  {detalleT.estadopv == 1 &&
                                    <OverlayTrigger overlay={<Tooltip>Enviar Prueba Vida</Tooltip>}>
                                      <button className="btn btn-main ms-1" onClick={() => pruebaVidaCreate()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                                          <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                                        </svg>
                                      </button>
                                    </OverlayTrigger>
                                  }
                                </span>
                              </div>
                            }
                          </div>
                        </div>
                      ))
                      }
                    </div> :
                    <div className="container">
                      <div className="row">
                        <div className="col fs-7 bold-1">
                          No se envio prueba de vida
                        </div>
                        <div className="col text-end">
                          <OverlayTrigger overlay={<Tooltip>Enviar Prueba Vida</Tooltip>}>
                            <button className="btn btn-main ms-1" onClick={() => pruebaVidaCreate()}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                              </svg>
                              &nbsp; Enviar &nbsp;
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div> :
                    <div className="d-grid text-center ">
                      <div className="container">
                        <div className="spinner-border text-success text-center" role="status" />
                      </div>
                      <span>Obteniendo datos...</span>
                    </div>
                  }
                </div>
                <div className="alert alert-info mt-2">
                  {estadoFirma.estado ?
                    <div>
                      <div className="container">
                        <div className="row row-cols-auto">
                          <div className="col fs-7"><strong>Firmas: </strong></div>
                          <div className="col fs-7"><strong>{estadoFirma.estado}</strong></div>
                        </div>
                      </div>
                      {estadoFirma.firmantes.length > 0 ?
                        <div className="container">
                          {estadoFirma.firmantes?.map((item, index) => (
                            <div key={index} className="row ">
                              <hr />
                              <div className=" col-xl-4 col-lg-4 col-12 text-center d-grid">
                                <span className="fs-8"><strong>Firmantes</strong></span>
                                <span className="fs-7" key={`firm_${index}n`}>{item.nombre}</span>
                              </div>
                              <div className=" col-xl-4 col-lg-4 col-5 text-center d-grid">
                                <span className="fs-8"><strong>Estado</strong></span>
                                <span className="fs-7" key={`firm_${index}e`}>{item.estado}</span>
                              </div>
                              <div className=" col-xl-4 col-lg-4 col-7 text-center d-grid">
                                {index == 0 ?
                                  <>
                                    <span className="fs-8"><strong>Fecha Firma</strong></span>
                                    <span className="fs-7" key={`firm_${index}f`}>{item.fechafirma}</span>
                                  </>
                                  : <>
                                    {item.linkfirma == "" ?
                                      <>
                                        <span className="fs-8"><strong>Fecha Firma</strong></span>
                                        <p className="fs-7" key={`firm_${index}f`}>{item.fechafirma}</p>
                                      </>
                                      :
                                      <>
                                        <span className="fs-8"><strong>Enlace Firma</strong></span>
                                        <span>
                                          <OverlayTrigger overlay={<Tooltip>Copiar Enlace</Tooltip>} >
                                            <button className="btn btn-main ms-1" onClick={() => copiarUrl(item.linkfirma)}>
                                              <svg xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-copy"
                                                viewBox="0 0 16 16">
                                                <path
                                                  fillRule="evenodd"
                                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                                />
                                              </svg>
                                            </button>
                                          </OverlayTrigger>
                                        </span>
                                      </>
                                    }
                                  </>
                                }
                              </div>
                            </div>
                          ))
                          }
                        </div>
                        : null
                      }
                    </div>
                    :
                    <div className="d-grid text-center ">
                      <div className="container">
                        <div className="spinner-border text-info text-center" role="status" />
                      </div>
                      <span>Obteniendo datos...</span>
                    </div>
                  }
                </div>
              </div>
              <div className="col-12 col-lg-3 d-flex align-items-center" style={{ borderRadius: "15px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}>
                <div className="container-fluid row m-0 p-0">
                  {trazabilidad?.length > 0 ?
                    <>
                      <p className="fs-6 text-center"> <strong>Trazabilidad</strong></p>
                      <ul className="custom-list" style={{ listStyle: "none" }}>
                        {trazabilidad.map((item, index) => {
                          return (
                            <li key={index} className="d-flex align-items-center me-3 me-lg-0 mb-2 fs-7"
                              style={{ fontWeight: item.estado == 2 ? "400" : "500", color: item.estado == 2 ? "darkgray" : "dark", animation: item.estado == 1 ? "parpadeo 3s infinite" : "none" }} >
                              {item.estado == 0 ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="green"
                                  className="bi bi-check-circle-fill me-1 mt-1 flex-shrink-0"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                              ) : item.estado == 1 ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-hourglass-bottom rotate-icon me-1 mt-1 flex-shrink-0" viewBox="0 0 16 16">
                                  <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5m2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="darkgray"
                                  className="bi bi-check-circle-fill me-1 mt-1 flex-shrink-0"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                              )}
                              {item.nombreEstado}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                    :
                    <div className="container">
                      <div className="d-grid text-center ">
                        <div className="container">
                          <div className="spinner-border text-dark text-center" role="status" />
                        </div>
                        <span>Obteniendo datos...</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

      <Modal show={showModalArchivosTramite} onHide={handleCloseArchivosTramite} size="xl" style={{"zIndex":"1050"}}>
        <Modal.Header className="text-center" closeButton style={{ borderBottom: "none", }}>
          <Modal.Title style={{ width: "100vw" }}>Archivos del Trámite {tramiteId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "100%" }} >
          <div className="mx-auto ps-0">
            <button
              className="btn btn-main d-flex align-items-center"
              onClick={() => setShowSubirArchivo(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-plus-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
              <span className="ms-1">Agregar</span>
            </button>
          </div>
          <div className="row p-3">
            {archivosByTramiteId.some(item => item.Files.length > 0) ? (
              archivosByTramiteId.map((section, secIndex) =>
                section.Files.length > 0 ? (
                  <div key={`archivos-${secIndex}`} className="col-12 mt-3 ">
                    <h5
                      className="text-center "
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {section.Descripcion}
                    </h5>
                    <div className="row p-2 mt-1" style={{
                      border: `1px solid ${colors[secIndex]}`,
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}>
                      {section.Files.map((a, index) => (
                        <div key={index} className="col-12 col-xl-2 col-lg-3 col-sm-6 pb-4">
                          <div
                            className="card shadow-sm h-100"
                            style={{
                              border: 'none',
                              borderRadius: '12px',
                              overflow: 'hidden',
                              transition: 'transform 0.3s ease',
                              backgroundColor: '#f8f9fa',
                              position: 'relative',
                              maxWidth: "80vw"
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.transform = 'scale(1.05)';
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.transform = 'scale(1)';
                            }}
                          >
                            <div
                              className="card-body text-center d-flex flex-column align-items-center"
                              style={{ position: 'relative' }}
                            >
                              <div
                                className="mb-1 rounded-circle d-flex align-items-center justify-content-center"
                                style={{
                                  width: '100px',
                                  height: '100px',
                                  backgroundColor: '#e9ecef',
                                  borderRadius: '100%'
                                }}
                              >
                                {a?.Nom_archv?.split(".").pop() === "zip" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="35"
                                    fill="#9700b7"
                                    className="bi bi-filetype-xml"
                                    viewBox="0 0 16 16"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <path d="M8.5 9.438V8.5h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243" />
                                    <path d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m2.5 8.5v.938l-.4 1.599a1 1 0 0 0 .416 1.074l.93.62a1 1 0 0 0 1.109 0l.93-.62a1 1 0 0 0 .415-1.074l-.4-1.599V8.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1m1-5.5h-1v1h1v1h-1v1h1v1H9V6H8V5h1V4H8V3h1V2H8V1H6.5v1h1z" />
                                  </svg>
                                )}
                                {a?.Nom_archv?.split(".").pop() === "pdf" && (
                                  <OverlayTrigger overlay={<Tooltip>Ver Archivo</Tooltip>} >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="red"
                                      className="bi bi-file-earmark-pdf"
                                      viewBox="0 0 16 16"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => verArchivo(a?.Nom_archv, a?.Archivo_Id, section.Files, index + 1)}
                                    >
                                      <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                    </svg>
                                  </OverlayTrigger>
                                )}
                                {a?.Nom_archv?.split(".").pop() === "xml" && (
                                  <OverlayTrigger overlay={<Tooltip>Ver Archivo</Tooltip>} >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="35"
                                      height="35"
                                      fill="#9700b7"
                                      className="bi bi-filetype-xml"
                                      viewBox="0 0 16 16"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => verArchivo(a?.Nom_archv, a?.Archivo_Id, section.Files, index + 1)}
                                    >
                                      <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999zm4.71-.674h1.696v.674H8.4V11.85h.791z" />
                                    </svg>
                                  </OverlayTrigger>
                                )}
                                {a?.Nom_archv?.split(".").pop() !== "pdf" &&
                                  a?.Nom_archv?.split(".").pop() != "zip" &&
                                  a?.Nom_archv?.split(".").pop() !== "xml" && (
                                    <OverlayTrigger overlay={<Tooltip>Ver Archivo</Tooltip>} >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="35"
                                        height="35"
                                        fill="#3f87ff"
                                        className="bi bi-image"
                                        viewBox="0 0 16 16"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => verArchivo(a?.Nom_archv, a?.Archivo_Id, section.Files, index + 1)}
                                      >
                                        <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                                      </svg>
                                    </OverlayTrigger>
                                  )}
                              </div>
                              <OverlayTrigger overlay={<Tooltip>Ver Archivo</Tooltip>} >
                                <h6 className="card-title text-break" style={{ fontSize: "0.7rem", cursor: "pointer" }} title="Ver archivo" onClick={() => verArchivo(a?.Nom_archv, a?.Archivo_Id, section.Files, index + 1)}  >{a?.Nom_archv}</h6>
                              </OverlayTrigger>
                              <OverlayTrigger overlay={<Tooltip>Ver Archivo</Tooltip>} >
                                <p className="card-text text-muted mb-2" style={{ fontSize: '0.6rem', cursor: "pointer" }} title="Ver archivo" onClick={() => verArchivo(a?.Nom_archv, a?.Archivo_Id, section.Files, index + 1)} >
                                  <small>{a?.Peso} KB</small>
                                </p>
                              </OverlayTrigger>
                              <OverlayTrigger overlay={<Tooltip>Eliminar Archivo</Tooltip>} >
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="#007bff"
                                  className="bi bi-delete"
                                  viewBox="0 0 16 16"
                                  style={{
                                    cursor: "pointer",
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    opacity: 0.8,
                                    transition: 'opacity 0.3s ease'
                                  }}
                                  onClick={() => {
                                    eliminarArchivo(a?.Archivo_Id)
                                  }}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.opacity = '1';
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.opacity = '0.8';
                                  }}>
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                </svg>
                              </OverlayTrigger>
                              <OverlayTrigger overlay={<Tooltip>Descargar Archivo</Tooltip>} >
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="#007bff"
                                  className="bi bi-download"
                                  viewBox="0 0 16 16"
                                  style={{
                                    cursor: "pointer",
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    opacity: 0.8,
                                    transition: 'opacity 0.3s ease'
                                  }}
                                  onClick={() => { descargarArchivo(tramiteId, a?.Nom_archv, a?.Archivo_Id) }}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.opacity = '1';
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.opacity = '0.8';
                                  }}>
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                </svg>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )
            ) : (
              <h3 className="text-center mt-3 text-muted">No se encontraron Archivos</h3>
            )}
          </div>
        </Modal.Body>
      </Modal >

      <Modal show={showSubirArchivo} onHide={handleCloseSubirArchivo} centered  >
        <form onSubmit={handleArchivo(onSubmit)}>
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title>Seleccione un archivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <select
                    id="archivoSelector"
                    style={{ width: "100%" }}
                    className="form-select"
                    defaultValue=""
                    {...registerArchivo("tipoArchivo", { required: "Este campo es requerido" })}
                  >
                    <option value="" disabled>Seleccione su tipo de archivo</option>
                    <option value="1">Certificados adicionales</option>
                    <option value="4">Aclaratorias</option>
                    <option value="2">Partida de matrimonio, partida de defuncion</option>
                    <option value="3">Cliente (otros)</option>
                  </select>
                  {errors.tipoArchivo && <span className="text-danger">{errors.tipoArchivo.message}</span>}
                </div>
                <div className="col-12">
                  {
                    watchArchivo("tipoArchivo") == "1" && (
                      <><select className="form-select my-2" defaultValue="GAS" {...registerArchivo("tipoCombustion")}
                      >
                        <option value="GAS">
                          GLP/GNV
                        </option>

                      </select><select className="form-select mb-2" {...registerArchivo("combustible")}>
                          {listaCombustible.map((c) => {
                            return <option value={c.codigo}>{c.descripcion}</option>;
                          })}
                        </select><div className="row">
                          <div className="col-6">
                            <label className="form-label m-0">Peso Neto <strong>(Kgr)</strong></label>
                            <input className="form-control" onKeyDown={allowOnlyNumbers} {...registerArchivo("pesoN", {
                              required: "El peso neto es obigatorio"
                            })} />
                            {errors.pesoN && <span className="text-danger">{errors.pesoN.message}</span>}
                          </div>
                          <div className="col-6">
                            <label className="form-label m-0">Carga Util <strong>(Kgr)</strong></label>
                            <input className="form-control" onKeyDown={allowOnlyNumbers} {...registerArchivo("cargaU", {
                              required: "La carga util es obligatoria"
                            })} />
                            {errors.cargaU && <span className="text-danger">{errors.cargaU.message}</span>}
                          </div>
                        </div></>
                    )

                  }
                </div>                
                <div className="col-12">
                  <input
                    id="inputFile"
                    className="form-control"
                    type="file"
                    accept=".pdf, .xml"
                    style={{ width: "100%", marginTop: "15px" }}
                    {...registerArchivo("archivo", {
                      required: "Agregue un archivo"
                    })}
                  />
                  {errors.archivo && <span className="text-danger">{errors.archivo.message}</span>}
                </div>

              </div>
            </div>

          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <button className="btn btn-secundario" onClick={(e) => { handleCloseSubirArchivo(e) }}>
              Cancelar
            </button>
            <button className="btn btn-main" type="submit"
            >

              Guardar
            </button>
          </Modal.Footer>
        </form>
      </Modal >





      <VisualizadorArchivos
        open={open}
        handleClose={handleCloseVisualizadorArchivo}
        idTramite={tramiteId}
        nombreArchivoVisualizar={nombreArchivoVisualizar}
        listaArchivosVisualizar={listaArchivosVisualizar}
        numeroArchivoInicial={numeroArchivoInicial}
      />
    </>
  );
}; 
import { api } from "./configApi";

export const getArchivos = async (dataPOST) => {
    try{
        const res = await api.post("api/archivo/listaArchivosV2", dataPOST)
        
        if(res?.status === 201){
            return{
                status: res?.data?.status,
                message: res?.data?.mensaje || "Archivos listados",
                archivos: res?.data?.archivos || []
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: "Error listar archivos",
                archivos: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            archivos: []
        };
    }
}

export const getArchivo = async (dataPOST) => {
    try{
        const res = await api.post("api/archivo/enviarArchivo", dataPOST)
    
        if(res?.status === 201){
            return{
                status: res?.data?.status,
                message: res?.data?.mensaje || "Archivo obtenido",
                archivo: res?.data?.archivo || ""
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: "Error listar archivos",
                archivo: ""
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            archivo: ""
        };
    }
}

export const subirArchivo = async (dataPOST) => {
    try{
        const res = await api.post("api/archivo/crearArchivo", dataPOST)
        // console.log(res)
        if(res?.status === 201){
            return{
                status: res?.data?.status,
                message: res?.data?.mensaje || "Archivo subido",
            }
        }
    } catch (error) {
        // console.log("error", error)
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: "Error listar archivos",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}
export const eliminarArchivoApi = async (dataPOST) => {
    try {
        const res = await api.post("/api/archivo/eliminar", dataPOST)        
        if (res?.status === 200) {
            return {
                status: res?.data?.status,                
                archivo: res?.data?.archivo || ""
            }
        }
    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0,
                message: "Error eliminar el archivo",                
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",            
        };
    }
}

